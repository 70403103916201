a {
  text-decoration: none;
  color: black;
}

li {
  margin-bottom: 25px;
}

.title-root {
  padding-top: 5%;
}

.link {
  text-decoration: none;
  padding-left: 2px;
  padding-right: 2px;
}

.navbar-root {
  left: 0;
  right: 0;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 1;
}

.navbar-right-container {
  margin-left: auto;
}

.navbar-right-container > * {
  margin-left: 12.5px;
  cursor: pointer;
}

.navbar-right-container > *:first-child {
  margin-left: 0px;
}

.flex-container {
  display: flex;
  justify-content: center;
  padding-top: 1%;
  width: 100%;
  flex-wrap: wrap;
}

.pill {
  border-radius: 4px;
  margin: 0 4px 4px 0;
  padding: 1px 4px;
}

#Python {
  background-color: var(--facebook-highlight);
  color: var(--facebook);
}

#Angular {
  background-color: rgba(181, 46, 49, 0.15);
  color: rgb(181, 46, 49);
}

#D3 {
  background-color: var(--caterpillar-highlight);
  color: var(--caterpillar);
}

#Flutter {
  background-color: var(--tableau-highlight);
  color: var(--tableau);
}

#Go {
  background-color: var(--salesforce-highlight);
  color: var(--salesforce);
}

#Neo4j {
  background-color: var(--ameren-highlight);
  color: var(--ameren);
}

#MySQL {
  background-color: var(--motorola-highlight);
  color: var(--motorola);
}

#dark-link {
  border-bottom: 2px solid var(--icon-main);
  padding-left: 2px;
  padding-right: 2px;
}

#dark-link:hover {
  background-color: var(--icon-secondary);
  border-bottom: none;
  border-radius: 4px;
  color: var(--icon-main);
}

#primary-link {
  border-bottom: 2px solid var(--pastel-primary);
}

#primary-link:hover {
  background-color: var(--pastel-primary-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--pastel-primary);
}

#secondary-link {
  border-bottom: 2px solid var(--pastel-secondary);
}

#secondary-link:hover {
  background-color: var(--pastel-secondary-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--pastel-secondary);
}

#tertiary-link {
  border-bottom: 2px solid var(--pastel-tertiary);
}

#tertiary-link:hover {
  background-color: var(--pastel-tertiary-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--pastel-tertiary);
}

#quaternary-link {
  border-bottom: 2px solid var(--pastel-quaternary);
}

#quaternary-link:hover {
  background-color: var(--pastel-quaternary-highlight);
  color: var(--pastel-quaternary);
}

#stripe {
  border-bottom: 2px solid var(--stripe);
}

#stripe:hover {
  background-color: var(--stripe-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--stripe);
}

#airkit {
  border-bottom: 2px solid var(--airkit);
}

#airkit:hover {
  background-color: var(--airkit-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--airkit);
}

#facebook {
  border-bottom: 2px solid var(--facebook);
}

#facebook:hover {
  background-color: var(--facebook-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--facebook);
}

#tableau {
  border-bottom: 2px solid var(--tableau);
}

#tableau:hover {
  background-color: var(--tableau-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--tableau);
}

#salesforce {
  border-bottom: 2px solid var(--salesforce);
}

#salesforce:hover {
  background-color: var(--salesforce-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--salesforce);
}

#motorola {
  border-bottom: 2px solid var(--motorola);
}

#motorola:hover {
  background-color: var(--motorola-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--motorola);
}

#caterpillar {
  border-bottom: 2px solid var(--caterpillar);
}

#caterpillar:hover {
  background-color: var(--caterpillar-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--caterpillar);
}

#ameren {
  border-bottom: 2px solid var(--ameren);
}

#ameren:hover {
  background-color: var(--ameren-highlight);
  border-bottom: none;
  border-radius: 4px;
  color: var(--ameren);
}

#uiuc-cs {
  border-bottom: 2px solid var(--uiuc-orange);
}

#uiuc-cs:hover {
  background-color: var(--uiuc-orange-secondary);
  border-bottom: none;
  border-radius: 4px;
  color: var(--uiuc-orange);
}

#resume-link {
  border-bottom: 2px solid var(--uiuc-orange);
}

#resume-link:hover {
  background-color: var(--uiuc-orange-secondary);
  border-bottom: none;
  border-radius: 4px;
  color: var(--uiuc-orange);
}
