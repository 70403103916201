.link:hover .tooltip {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.tooltip {
  background: #c8c8c8;
  color: black;
  display: none;
  font-size: 14px;
  padding: 4px;
  position: absolute;
  white-space: nowrap;
  z-index: 1;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
