.footer-root {
  background-color: var(--icon-main);
  text-align: center;
  padding-bottom: 2px;
  padding-top: 12px;
}

.credit {
  color: var(--light-secondary);
  margin-top: 4px;
}
