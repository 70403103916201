.more-root {
  /* background-color: #f2f2f2; */
  text-align: center;
}

.cat-picture {
  border-radius: 50%;
  width: 15%;
}

@media (max-width: 800px) {
  .cat-picture {
    width: 50%;
  }
}

.counter {
  padding: 0;
}

.counter-text {
  margin-bottom: 0;
  padding-bottom: 2%;
}
